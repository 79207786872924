/* src/About.css */

.about-page {
    font-family: Arial, sans-serif;
    
  }
  
  .about-section {
    padding: 20px;
    background-color:  #f0f8ff; /* Light blue background */
    display: flex;
    justify-content: center;
    width: 100%;
    height: 70vh;
  }
  
  .about-content {
    max-width: 800px;
    text-align: left;
    padding: 20px;
    background-color:  #e0f7ff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .about-title {
    color: #d32f2f; /* Red color for "About us" */
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .services h2 {
    color: #333;
    font-size: 1.5em;

    margin-bottom: 10px;
  }
  
  .services ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    color: #555;
  }
  
  .services ul li {
    margin-bottom: 10px;
    line-height: 1.5;
    font-size: 1em;
  }
  
  .contact-info {
    margin-top: 20px;
    font-size: 0.9em;
    color: #555;
  }
  
  .contact-info p {
    margin: 5px 0;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .about-content {
      padding: 15px;
      margin: 0 10px;
    }
  
    .about-title {
      font-size: 1.8em;
    }
  
    .services h2 {
      font-size: 1.3em;
    }
  
    .services ul li {
      font-size: 0.95em;
    }
  
    .contact-info {
      font-size: 0.85em;
    }
  }
  