/* Navbar.css */

/* Upper Section */
.navbar-upper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2rem;
    background-color:  rgb(128, 206, 232); /* Sky blue color */
}

.navbar-logo img {
    height: 50px;
}

.navbar-contact {
    display: flex;
    gap: 1rem;
    font-size: 0.9rem;
    color: #100101;

}

/* Lower Section */
.navbar-lower {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    background-color: #ffffff; /* White color */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
}

/* Toggle Button */
.navbar-toggle {
    display: none;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    color: #000;
    left: 200px;
    top:9px;
}


/* Links and Button */
.navbar-links {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    gap: 1.5rem;
}
.navbar-links li a {
    text-decoration: none;
    color: #000;
    font-weight: bold;
}

.navbar-button {
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    /* This will push the button to the right */
}

.navbar-button:hover {
    background-color: #0056b3;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
    .navbar-lower {
        flex-direction: column;
        align-items: flex-start;
        
    }

    .navbar-toggle {
        display: block;
        position: absolute;
        left:2px;
        top: rem;
    }

    .navbar-links {
        display: none;
        flex-direction: column;
        width: 100%;
        gap: 1rem;
        background-color: #ffffff;
        padding: 1rem 2rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        
    }

    .navbar-links.active {
        display: flex;
    }

    .navbar-button {
        margin-top: 1rem;
        width: 100%;
        text-align: center;
    }
     .next-contact-button {
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
        margin-left: 8px;
    }
}
@media (min-width: 769px) {
    .next-contact-button {
        display: none;
    }
}