.hero-section {
    height: 80vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #333;
    position: relative;
}

.hero-content {
    
    padding: 2rem;
    border-radius: 8px;
    max-width: 600px;
}

.hero-content h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.hero-content h1 span {
    color: #32cd32; /* Environment text in green */
}

.hero-content p span {
    color: #32cd32; /* Doorstep text in green */
}

.explore-button {
    padding: 0.5rem 1.5rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 1rem;
}

.explore-button:hover {
    background-color: #0056b3;
}
