/* ComingSoon.css */
.ComingSoon {
    font-family: Arial, sans-serif;
}


.coming-soon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    background-color: #f8f9fa; /* Light background */
}

.coming-soon-container h1 {
    font-size: 4rem;
    color: #333; /* Dark text color */
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4); /* Add shadow */
    text-align: center; /* Center-align text */
}

/* Adjust font size for smaller screens */
@media (max-width: 768px) {
    .coming-soon-container h1 {
        font-size: 3rem; /* Slightly smaller font for mobile */
    }
}

@media (max-width: 480px) {
    .coming-soon-container h1 {
        font-size: 2.5rem; /* Even smaller font for very small screens */
    }
}