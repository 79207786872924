/* Consultancy.css */

.consultancy {
    font-family: Arial, sans-serif;
   
}

.consultancy-header {
    text-align: center;
    padding: 2rem;
    background-color: #f0f8ff;
}

.consultancy-header h1 {
    color: #e60000;
    font-size: 2.5rem;
}

.consultancy-button {
    background-color: #007bff;
    color: white;
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 1rem;
}

.consultancy-cards {
    display: flex;
    justify-content: center;
    gap: 5rem;
    flex-wrap: wrap;
    padding: 2rem;
    
}

.consultancy-card {
    background-color: #e0f7ff;
    border-radius: 15px;
    padding: 1rem;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    
}

.consultancy-card img {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.consultancy-card h2 {
    color: #007bff;
    font-size: 1.25rem;
}

.consultancy-card p {
    color: #666;
    font-size: 1rem;
    margin-top: 0.5rem;
}

.consultancy-card ul {
    list-style-type: none;
    padding: 0;
    font-size: 0.9rem;
    color: #333;
}

.consultancy-card li {
    padding: 0.2rem 0;
}

/* Responsive Design */
@media (max-width: 768px) {
    .consultancy-cards {
        flex-direction: column;
        align-items: center;
    }

    .consultancy-header h1 {
        font-size: 2rem;
    }

    .consultancy-button {
        font-size: 0.9rem;
    }

    .consultancy-card {
        width: 90%;
    }
}
